/* rubik-300 - latin */
@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url('Fonts/rubik-v28-latin-300.woff2') format('woff2');
}
/* rubik-300italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  src: url('Fonts/rubik-v28-latin-300italic.woff2') format('woff2');
}
/* rubik-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('Fonts/rubik-v28-latin-regular.woff2') format('woff2');
}
/* rubik-italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  src: url('Fonts/rubik-v28-latin-italic.woff2') format('woff2');
}
/* rubik-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url('Fonts/rubik-v28-latin-500.woff2') format('woff2');
}
/* rubik-500italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  src: url('Fonts/rubik-v28-latin-500italic.woff2') format('woff2');
}
/* rubik-600 - latin */
@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  src: url('Fonts/rubik-v28-latin-600.woff2') format('woff2');
}
/* rubik-600italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  src: url('Fonts/rubik-v28-latin-600italic.woff2') format('woff2');
}
/* rubik-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: url('Fonts/rubik-v28-latin-700.woff2') format('woff2');
}
/* rubik-700italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  src: url('Fonts/rubik-v28-latin-700italic.woff2') format('woff2');
}
/* rubik-800 - latin */
@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  src: url('Fonts/rubik-v28-latin-800.woff2') format('woff2');
}
/* rubik-800italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  src: url('Fonts/rubik-v28-latin-800italic.woff2') format('woff2');
}
/* rubik-900 - latin */
@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  src: url('Fonts/rubik-v28-latin-900.woff2') format('woff2');
}
/* rubik-900italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  src: url('Fonts/rubik-v28-latin-900italic.woff2') format('woff2');
}
